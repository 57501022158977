import React from "react";
import { Container, Row, Col,Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUsers, faCity} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
     
        <PageWrapper footerDark>
 <Helmet>
                <meta charSet="utf-8" />
                <title>Banque pour restaurants - Meilleure banque pro pour votre restaurant</title>
                <meta name="description" content="Vous avez un restaurant? Trouvez la meilleure banque pro avec bankbank." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque pro pour restaurant</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque pro pour votre restaurant</h1>
          <h2 id='compareh2' style={{ color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro restaurants</span> pour déterminer vos priorités : employé.e.s, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Société avec 1 employé.e </Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 employé.es</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 employé.es</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Plus de 50 employé.es</Tab>
    </TabList>

    <TabPanel>
      <DataTable type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>      
      <DataTable type='Société' row={[12,4,18,9,19,30,20]} id='wideTable'/>
      <DataTableMobile type='Société' row={[12,4,18,9,19,30,20]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>    
      <DataTable type='Société' row={[9,21,4,22,23,31]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,4,22,23,31]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38]} id='mobileTable'/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
  
            

       
              </Col>
            </Row>
             
          </Container>

          <Container style={{marginTop: 100, marginBottom : 100}}>
		
			<Post>

			<h2>Comment trouver la meilleure banque pro pour votre restaurant ?</h2>
			<p>
La restauration a connu des bouleversements importants dû principalement à l’avènement de la livraison à domicile. Cette nouvelle manière de consommer a eu des répercussions directes et indirectes sur tout le secteur.
</p>
<StaticImage
		  	src='../../images/banque-pro-restaurant.jpeg'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<p>
La maturité des plateformes de livraison comme Deliveroo et Ubereats permet désormais à n’importe quel restaurant d’ouvrir son activité à des revenus supplémentaires avec la production de plats consommés à domicile. Mais ce type de restauration ne plaît pas à tout le monde. La qualité du produit fini peut varier fortement si la cuisine n’est pas optimisée pour des délais rallongés entre la cuisson et la dégustation.
</p>
<h3>
2 effets sur le secteur de la restauration :</h3>
<h4>
1 – L’arrivée des restaurants “sans restaurant” et les dark kitchen.</h4>
<p>
Se rendant compte des problèmes liés à la chaîne de livraison, certains entrepreneurs ont lancé des marques de restaurants sans restaurant. Concrètement, il s’agit de cuisines sans salle, sans chaise et sans table qui se concentrent sur la fabrication de plats qui, en théorie, arriveront chez vous dans un meilleur état. C’est les dark kitchen (cuisine sombre, cuisine dans l’ombre).
</p><p>
À l’image des banques en ligne sans guichets, la réduction de coûts de ses darks kitchens leur permet d’investir dans du budget marketing qui fera vivre leur marque sur le web.
</p>
<h4>
2 – La quasi disparition du pas de porte</h4>
<p>
Il y’a quelques années, la position de votre établissement dans une rue passante était une des clés de la réussite d’un restaurant. L’accès à une adresse avait une valeur. Et le paiement d’un pas de porte était souvent la norme en début de baille.
</p><p>
Aujourd’hui, les centres-villes se vident et la livraison à domicile permet de vendre ses plats à jusqu’à 3 km de son établissement. Cette distance varie selon le trafic des zones urbaines dans laquelle on se trouve.
</p><p>
L’adresse exact de votre restaurant à moins d’importance et le pas de porte est devenu une manière vieillotte d’effectuer une transaction autour d’un fond de commerce.
</p><p>
Voila ! Le monde de la restauration change, et vous vous devez de vous adapter aux nouvelles manières de consommer. Vous ne verrez peut-être jamais certains clients. Bientôt, vous n’aurez plus besoin d’un tiroir caisse pour encaisser du liquide. Et comme Netflix qui produit des films selon les interactions de ses membres, vous allez probablement créer des plats selon les avis de vos clients.
</p><p>
Il est primordial d’accompagner votre restaurant d’une banque qui saura évoluer avec vous et votre marché. Peut-être même 2 banques ! Une banque traditionnelle pour le liquide et les chèques et une banque pro en ligne pour économiser sur toutes vos transactions cartes bancaires et simplifier vos quotidien avec une comptabilité automatique.
</p><h3>
Et donc… quelle banque pro choisir pour mon restaurant ?</h3>
<p>
En répondant à quelques questions sur votre activité, vous pouvez utiliser le comparateur banque pro de BankBank pour vous guider dans le choix du meilleure compte pro. Bonne chasse !
</p>

			</Post>
		</Container>
		<Footer/>
      </PageWrapper>

    )
}

export default startabusiness
